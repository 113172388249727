import { red } from "@material-ui/core/colors"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      // main: "#093473",
      main: "#0f123d",
      // light: "#9898a9",
      dark: "#031242",
      // main: "#2979ff",
      // main: "#00008B",
      // contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      // contrastText: "#9898a9",
      // contrastText: "#000",
    },
    info: {
      main: "#fff",
      contrastText: "#9898a9",
    },
    error: {
      main: "#FF8C00",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#393940",
      // secondary: "#00008B",
    },
  },
  typography: {
    h1: {
      fontFamily: '"Heboo", sans-serif',
    },
    h2: {
      fontFamily: '"Heboo", sans-serif',
      // fontWeight: 900,
    },
    h3: {
      fontFamily: '"Heboo", sans-serif',
      // fontWeight: 900,
    },
    h4: {
      fontFamily: '"Heboo", sans-serif',
      // fontWeight: 900,
    },
    h5: {
      fontFamily: '"Heboo", sans-serif',
      // fontWeight: 500,
    },
    h6: {
      fontFamily: '"Heboo", sans-serif',
      textTransform: "uppercase",
      // fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Lato", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: "1.25rem",
      // fontWeight: 300,
    },
    body1: {
      fontFamily: '"Lato", sans-serif',
      // fontSize: 20,
    },
    body2: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: "1rem",
      // fontWeight: 800,
    },
    caption: {
      fontFamily: '"Lato", sans-serif',
    },
    overline: {
      fontFamily: '"Heboo", sans-serif',
      fontSize: 15,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 96,
    },
  },
  shape: {
    // borderRadius: 0,
  },
  props: {
    MuiButtonBase: {
      // disableRipple: true, // disable ripple effect
    },
  },
})

export default responsiveFontSizes(theme)
